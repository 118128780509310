<template>
  <div class="branche">
    <div class="branche-details">
        <div v-if="platform!='mobile'" class="left-side">
           <i class="material-icons arrow-left-icon">arrow_circle_left</i>
        </div>
        <div class="right-side">
            <h1 style="font-weight: bold;">{{branche_data.branche_name}}</h1>
            <h2 style="font-weight: bold;">{{branche_data.address}}</h2>
            <div class="hours-details-field">
                <p style="font-size: 22px;">שעות פתיחה:</p>
                <p style="font-size: 18px;" class="description">{{branche_data.description}}</p>
            </div>
            <div class="phone-and-nevigates-icons-details">
                <div class="phone-section">
                    <p class="phone">
                        <i class="material-icons">call</i>
                        {{branche_data.phone}}
                        <a :href="`tel:${branche_data.phone}`"></a>
                    </p>
                </div>
                <div class="nevigates-icons">
                     <!-- <img style="height: 75%; width: auto; margin-left: 10px;" :src="nivut" alt="ניווט"> -->
                    <a class="nevigate-btn"  :href="`https://www.waze.com/ul?q=${branche_data.address}`" target="_blank">
                        סעו עם WAZE 
                        <img class="navigation-icon" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fwaze%20(2)_375x375.png?alt=media&token=a02be628-4708-465a-8ab1-13c64b240b48">
                    </a>
                    <a class="nevigate-btn"  :href="branche_data.map_url" target="_blank">
                        <!-- <img class="navigation-icon" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fwaze%20(2)_375x375.png?alt=media&token=a02be628-4708-465a-8ab1-13c64b240b48"> -->
                       סעו עם Google   <img class="navigation-icon" style="margin-right:10px;" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fgoogle-maps%20(1)_375x375.png?alt=media&token=6d157489-23bc-477b-815b-da4ff9e760a5">
                    </a>
                    <!-- <a :href="branche_data.map_url"  target="_blank">
                        <img class="navigation-icon" style="margin-right:10px;" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fgoogle-maps%20(1)_375x375.png?alt=media&token=6d157489-23bc-477b-815b-da4ff9e760a5">
                    </a> -->
                </div>
            </div>

        </div>
    </div>
    <div class="branche-footer">
        <div class="dash-line"></div>
    </div>
    
    <i v-if="role>=99" @click="handle_edit_branche" class="material-icons edit-icon">edit</i>
    <i v-if="role>=99" @click="handle_delete_branche(branche_data)"  class="material-icons delete-icon">delete</i>

    <!-- <h1 class="branche-name">{{branche_data.branche_name}}</h1>
    <h2>{{branche_data.address}}</h2> -->

    <!-- <i v-if="role>=99" @click="handle_edit_branche" class="material-icons edit-icon">edit</i>
    <i v-if="role>=99" @click="handle_delete_branche(branche_data)"  class="material-icons delete-icon">delete</i> -->


    <!-- <div ref="content" class="branche-content">
        <div class="branche-content-details">
            <div class="branche-content-details-field">
                <h2>שעות פתיחה:</h2>
                <p class="description">{{branche_data.description}}</p>
            </div>
            <div class="branche-content-details-field">
                <h2>טלפון:</h2>
                <p class="phone">
                    <i class="material-icons">call</i>
                    {{branche_data.phone}}
                    <a :href="`tel:${branche_data.phone}`"></a>
                </p>
            </div>
            <div class="branche-content-details-field">
                <h2>הסדרי נגישות</h2>
                <p class="description">{{branche_data.accessibility}}</p>
            </div>
            <div class="branche-content-details-field">
                <h1 data-open="open" v-if="checkTime(branche_data.opening_hours)">פתוח</h1>
                <h1 data-open="close" v-if="!checkTime(branche_data.opening_hours)">סגור</h1>
            </div>
            <div class="branche-content-details-field">
                <h2>ניווט:</h2>
                <a :href="`https://www.waze.com/ul?q=${branche_data.address}`" target="_blank">
                    <img class="navigation-icon" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fwaze%20(2)_375x375.png?alt=media&token=a02be628-4708-465a-8ab1-13c64b240b48">
                </a>
                <a :href="branche_data.map_url"  target="_blank">
                    <img class="navigation-icon" style="margin-right:10px;" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fgoogle-maps%20(1)_375x375.png?alt=media&token=6d157489-23bc-477b-815b-da4ff9e760a5">
                </a>
            </div>
            <div class="logo">
                <img :src="branche_data.logo">
            </div>
        </div>
        <div class="branche-content-map">
            <iframe class="map" :src="branche_data.map_embedded" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div> -->
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {alert_confirm} from '../../Methods/Msgs'
import {delete_branche,checkTime,role} from './Scripts/scripts'
import nivut from './img/nivut.jpg'
import {} from '../../Methods/Utils'
import store from '../../store'
import { computed } from 'vue'
export default {
    props:['branche_data'],
    emits:['edit','delete_branche','scroll'],
    setup(_,{emit}){
        const arrow_icon = ref()
        const content = ref()

        const platform = ref(computed(()=>{
            return store.state.platform
        }))

        const handle_delete_branche = (branche)=>{
            alert_confirm(`האם למחוק את סניף ${branche.branche_name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    await delete_branche(branche)
                    emit('delete_branche',branche)
                }
            })
        }

        const handle_show_content = ()=>{
            arrow_icon.value.classList.toggle("up");
            content.value.classList.toggle("open")
            if(content.value.classList.contains('open')){
                emit('scroll',arrow_icon)
            }
        }

        const handle_edit_branche = ()=>{
            emit('edit')
        }

        return{
            handle_show_content,
            handle_edit_branche,
            handle_delete_branche,
            checkTime,
            arrow_icon,
            content,
            role,
            nivut,
            platform
        }
    }
}
</script>

<style scoped>
    .branche{
        position: relative;
        width: 100%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .branche-details{
        width: 100%;
        flex: 1;
        display: flex;
    
    }
    .left-side{
        position: relative;
        width: 100px;
        height: 100%;
    }
    .right-side{
        flex: 1;
        height: 100%;
        border-right: 3px solid black;
        padding: 0 5px;
    }
    .hours-details-field{
        width: 100%;
        padding: 0 5px;
        margin-top: 5px;
    }
    .hours-details-field > *{
        font-weight: 500;
      
    }
    .phone-and-nevigates-icons-details{
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
    }
    .phone-and-nevigates-icons-details > .phone-section,.phone-and-nevigates-icons-details > .nevigates-icons{
        width: auto;
        height: 50%;
        display: flex;
        align-items: center;
    }
    .phone-and-nevigates-icons-details > .nevigates-icons{
        justify-content: center;
    }
    .phone-and-nevigates-icons-details > .nevigates-icons img{
        width: 30px;
    }
    .phone{
        position: relative;
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .branche-footer{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .dash-line{
        width: 90%;
   
        border: 1px dashed black;
        margin: 0 auto;
    }
    .nevigate-btn{
        padding: 10px;
        border: 0;
        border-radius: 10px;
        background: black;
        color: #fff;
        cursor: pointer;
        margin: 0 5px;
        display: flex;
        align-items: center;
        font-size: 15px;
    }

    
    
    












    .arrow-icon,.edit-icon,.delete-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        font-size: 3.5rem;
        user-select: none;
        color: grey;
        cursor: pointer;
        transition: all 0.2s ease-in;
    }
    .arrow-left-icon{
        position: absolute;
        top: 10px;
        left: 10px;
        user-select: none;
        font-size: 50px;
    }
    .edit-icon{
        left: 0;
        font-size: 2rem;
        transform: unset;
        top: 0;
        color: var(--warning);
    }
    .delete-icon{
        left: 0;
        font-size: 2rem;
        transform: unset;
        top: 50px;
        color: var(--danger);
    }
    .arrow-icon.up{
        transform: rotate(-180deg) translateY(50%);
    }
    .branche-name{
        font-size: 2.5rem;
    }
    .branche-content{
        width: 100%;
        height: 0;
        transition: all 0.3s ease-in;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        overflow: hidden;
    }
    .branche-content > div{
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in;
    }
    .branche-content.open{
        height: auto;
    }
    .branche-content.open > div{
        opacity: 1;
    }
    
    .branche-content-details{
        position: relative;
        width: 40%;
        height: 100%;
    }
    
    
  

    .phone a::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    [data-open='open']{
        color: var(--success);
    }
    [data-open='close']{
        color: var(--danger);
    }
  
    .logo{
        position: absolute;
        top: 0;
        left: 5px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo img{
        max-width: 100%;
        max-height: 100%;
    }
    .description{
        white-space: pre-wrap;
        line-height: 1.5;
    }
    .branche-content-map{
        width: 60%;
        height: 100%;
       
    }
    .branche-content-map .map{
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .navigation-icon{
        cursor: pointer;
        margin: 0 5px;
    }
    @media screen and (max-width: 600px) {
        .branche-content{
            grid-template-columns: repeat(1,1fr);
        }
        .branche-content.open{
            height: auto;
        }
        .branche-content-details{
            width: 100%;
            height: auto;
        }
        .branche-content-map{
            width: 100%;
        }
        .branche-content-details-field p{
            font-size: 18px;
        }
    }
    .slide-in-right {
	    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }  
    @-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
                    transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }

    i:active{
        -webkit-tap-highlight-color: transparent;
    }

   
</style>