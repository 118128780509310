<template>
  <div v-loading="is_pending" class="branches">
    <div class="branches-tools">
        <router-link :to="{name:'BranchesMap'}">
                <img class="map-icon" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fmap_375x375.png?alt=media&token=6e26f29c-ea7f-4de7-9f2f-87d6a262456e" alt="">
        </router-link>
        <i v-if="role>=99" @click="show_create_branche_form = !show_create_branche_form" class="material-icons add-icon">add</i>
        <el-input style="width:20%;" v-model="search" placeholder="חיפוש" />
            <el-select  style="margin-right:5px;" v-model="sort_by" class="m-2" placeholder="מיין לפי" size="large">
                <el-option
                v-for="option in sort_options"
                :key="option"
                :value="option"
                />
        </el-select>
    </div>
 
    <div ref="branches_wrapper_content" class="branches-wrapper-content">
        
        <template v-for="branche in sort_by_option" :key="branche.uid">
            <Branche :branche_data="branche" @edit="handle_edit(branche)"
                @delete_branche="handle_delete_branche" @scroll="handle_scroll" />
        </template> 
    </div>

    <!-- <div v-loading="is_pending" class="branches-wrapper"> -->
        <!-- <div class="branches-wrapper-header">
            <router-link :to="{name:'BranchesMap'}">
                <img class="map-icon" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fmap_375x375.png?alt=media&token=6e26f29c-ea7f-4de7-9f2f-87d6a262456e" alt="">
            </router-link>
            <h1>רשימת סניפים</h1>
            <i v-if="role>=99" @click="show_create_branche_form = !show_create_branche_form" class="material-icons add-icon">add</i>
        </div> -->
        <!-- <div class="branches-wrapper-tools">
            <el-input style="width:40%;" v-model="search" placeholder="חיפוש" />
            <el-select style="margin-right:5px;" v-model="sort_by" class="m-2" placeholder="מיין לפי" size="large">
                <el-option
                v-for="option in sort_options"
                :key="option"
                :value="option"
                />
            </el-select>
        </div> -->
    <!-- </div> -->
    <CreateNewBranche @close="handle_close" 
    @finish="handle_finish" @edit_finish="handle_edit_finish"
    v-if="show_create_branche_form" :edit_data = selected_branche_for_edit />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import CreateNewBranche from '../components/Branches/CreateNewBranche.vue'
import {get_all_branches,role,checkTime} from '../components/Branches/Scripts/scripts'
import Branche from '../components/Branches/Branche.vue'
import { computed } from '@vue/runtime-core'

export default {
    components:{CreateNewBranche,Branche},
    setup(){
        const sort_by = ref('')
        const search = ref('')
        const is_pending = ref(false)
        const branches = ref([])
        const selected_branche_for_edit = ref(null)
        const branches_wrapper_content = ref()
        const show_create_branche_form = ref(false)
        const sort_options = ref([
            'הצג הכל',
            'פתוח כעת',
            'פתוח בשבת',
        ])
        const sort_by_option = ref(computed(()=>{
           if(!sort_by.value || sort_by.value == 'הצג הכל') return sort_by_order.value
           if(sort_by.value == 'פתוח בשבת') return sort_by_order.value.filter(branche=>branche.opening_hours[6].open)
           if(sort_by.value == 'פתוח כעת') return sort_by_order.value.filter(branche=>checkTime(branche.opening_hours))
        }))

        const sort_by_order = ref(computed(()=>{
            return filter_by_search.value.sort((a,b) => a.order - b.order)
        }))
        const filter_by_search = ref(computed(()=>{
            if(!search.value) return branches.value
            return branches.value.filter(branche=>{
                if(branche.branche_name.includes(search.value)) return branche
                if(branche.address.includes(search.value)) return branche
                if(branche.phone.includes(search.value)) return branche
            })
        }))
        const handle_scroll  = (to)=>{
            branches_wrapper_content.value.scrollTo({ top: to.value.scrollIntoView(), behavior: 'smooth' })
        }
        const handle_delete_branche = (branche)=>{
            const index = branches.value.findIndex(_branche=>_branche.uid == branche.uid)
            index!=-1 && branches.value.splice(index,1)
        }
        const handle_close  = ()=>{
            show_create_branche_form.value=false
            selected_branche_for_edit.value = null
        }
        const handle_edit = (branche_data)=>{
            selected_branche_for_edit.value = JSON.parse(JSON.stringify(branche_data))
            show_create_branche_form.value = true
        }
        const handle_finish = (data)=>{
            branches.value.push(data)
            show_create_branche_form.value = false
            selected_branche_for_edit.value = null
        }

        const handle_edit_finish = (data)=>{
            const index = branches.value.findIndex(branche=>branche.uid == data.uid)
            if(index!=-1){
                branches.value[index] = data
            }
            show_create_branche_form.value = false
            selected_branche_for_edit.value = null
        }

        const init = async()=>{
            is_pending.value = true
            branches.value = await get_all_branches()
            is_pending.value = false
        }

        init()
        return{
            handle_edit,
            handle_finish,
            handle_close,
            handle_edit_finish,
            show_create_branche_form,
            selected_branche_for_edit,
            handle_delete_branche,
            is_pending,
            branches,
            filter_by_search,
            search,
            role,
            branches_wrapper_content,
            handle_scroll,
            sort_by_order,
            sort_options,
            sort_by,
            sort_by_option
        }
    }
}
</script>

<style scoped>
    .branches{
        width: 100%;
        height: 100%;
        background: #FAFA37;
        display: flex;
        flex-direction: column;
    }
    .branches-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background: black;
    }
    .branches-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    .branches-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .branches-wrapper-tools{
        width: 100%;
        height: 50px; 
        display: flex;
        align-items: center;
    }
    .branches-wrapper-content{
        width: 100%;
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        padding: 5px;
    }
    .branches-wrapper-content::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        height: 0;
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    
    .add-icon,.delete-icon,.map-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        font-size: 30px;
        color: var(--success);
        cursor: pointer;
        user-select: none;
    }
    .add-icon{
        left: 40px;
        color: #fff;
    }
    .map-icon{
        height: 32px;
    
    }

</style>