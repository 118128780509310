<template>
  <div class="create-new-branche">
    <div v-loading="is_pending" class="create-new-branche-form slide-in-top">
      <div class="create-new-branche-form-header">
        <h1>יצירת סניף</h1>
        <div class="exit-btn">
             <i @click="$emit('close')" class="material-icons add-icon">close</i>
        </div>
      </div>
      <div class="create-new-branche-form-content">
        <div class="create-new-branche-form-content-field">
            <h2>שם הסניף</h2>
            <el-input v-model="values.branche_name" placeholder="שם הסניף" />
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>כתובת הסניף</h2>
            <el-input v-model="values.address" placeholder="כתובת מלאה" />
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>טלפון הסניף</h2>
            <el-input type="tel" v-model="values.phone" placeholder="טלפון" />
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>פרטי נגישות</h2>
            <el-input
                class="text-area"
                v-model="values.accessibility"
                :rows="2"
                type="textarea"
                placeholder="נא לתאר את הנגישות בסניף"
            />
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>הטמעת מפה של גוגל</h2>
            <el-input v-model="values.map_embedded" placeholder="קישור של המפה" />
            <iframe v-if="values.map_embedded" :src="values.map_embedded" width="200" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>הוספת קישור לפתיחה של המפה</h2>
            <el-input v-model="values.map_url" placeholder="קישור של המפה" />
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>בחירת לוגו של סוג הסניף</h2>
            <el-select style="width:100%;" v-model="values.logo" class="m-2" placeholder="בחירת לוגו" size="large">
                <el-option
                v-for="logo in logo_options"
                :key="logo"
                :label="logo.label"
                :value="logo.value"
                />
            </el-select>
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>סדר הצגה</h2>
            <el-input-number style="width:100%;" v-model="values.order" :min="1" :max="100"/>
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>שעות פתיחה</h2>
            <template v-for="day in values.opening_hours" :key="day.day_index">
                <div class="day">
                    <label>
                        <p> יום {{day.day_name}}</p>
                        <el-switch
                            v-model="day.open"
                            class="ml-2"
                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                        />  
                    </label>
                   
                    <label v-if="day.open">
                        משעה:
                        <el-input type="time" v-model="day.start_time"  placeholder="Please input" />
                    </label>
                    <label v-if="day.open">
                        עד שעה:
                        <el-input type="time" v-model="day.end_time" placeholder="Please input" />
                    </label>

                    <h1 class="close-msg" v-if="!day.open">סגור ביום זה</h1>
                </div>
            </template>
        </div>
        <div class="create-new-branche-form-content-field">
            <h2>תיאור שעות פתיחה</h2>
            <el-input
                class="text-area"
                v-model="values.description"
                :rows="4"
                type="textarea"
                placeholder="נא לתאר את שעות הפתיחה בסניף"
            />
        </div>
        <div class="create-new-branche-form-content-field">
            <el-button v-if="!edit_data" @click="handle_submit" style="width:100%" type="success">סיום</el-button>
            <el-button v-if="edit_data" @click="handle_submit_edit" style="width:100%" type="warning">עדכון</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../Methods/Msgs'
import {add_new_branche,edit_exist_branche} from './Scripts/scripts'

export default {
    emits:['close','edit_finish'],
    props:['edit_data'],
    setup(props,{emit}){

        const logo_options = ref([
            {
                value:'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Frosman-market_375x375.png?alt=media&token=b055241e-d401-4ae2-9c14-178553610dc2',
                label:'רוסמן מרקט'
            },
            {
                value:'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Fbuty_pharm_375x375.png?alt=media&token=99550e19-294e-4fa6-9bb0-643660c2f791',
                label:'ביוטי פארם'
            },
            {
                value:'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Froman-logistic_375x375.png?alt=media&token=4efd29d7-2d7d-4fae-99b3-f467a7c4d693',
                label:'רוסמן לוגיסטיק'
            },
            {
                value:'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Fstockcity.jpeg?alt=media&token=0ce95bc2-0633-4350-bf0d-14c07ccece5b',
                label:'סטוק סיטי'
            },
        ])
        const is_pending = ref(false)

        const err_msg = ref(false)

        const values = ref({
            branche_name:'',
            address:'',
            phone:'',
            accessibility:'',
            map_embedded:'',
            map_url:'',
            logo:'',
            order:undefined,
            opening_hours:[
                {
                    day_name:'א\'',
                    day_index:0,
                    start_time:'',
                    end_time:'',
                    open:true
                },
                {
                    day_name:'ב\'',
                    day_index:1,
                    start_time:'',
                    end_time:'',
                    open:true
                },
                {
                    day_name:'ג\'',
                    day_index:2,
                    start_time:'',
                    end_time:'',
                    open:true
                },
                {
                    day_name:'ד\'',
                    day_index:3,
                    start_time:'',
                    end_time:'',
                    open:true
                },
                {
                    day_name:'ה\'',
                    day_index:4,
                    start_time:'',
                    end_time:'',
                    open:true
                },
                {
                    day_name:'ו\'',
                    day_index:5,
                    start_time:'',
                    end_time:'',
                    open:true
                },
                {
                    day_name:'ש\'',
                    day_index:6,
                    start_time:'',
                    end_time:'',
                    open:true
                },
            ],
            description:''
        })

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'branche_name':
                        if(!value){
                          err_msg.value = 'עליך להזין את שם הסניף!'
                          return false  
                        }
                        break;
                    case 'address':
                        if(!value){
                          err_msg.value = 'עליך להזין את כתובת הסניף!' 
                          return false 
                        }
                        break;
                    case 'phone':
                        if(!value){
                          err_msg.value = 'עליך להזין את טלפון הסניף!' 
                          return false 
                        }
                        break;
                    case 'accessibility':
                        if(!value){
                          err_msg.value = 'עליך לרשום את פרטי הנגישות של הסניף!'  
                          return false
                        }
                        break;
                    case 'map_embedded':
                        if(!value){
                          err_msg.value = 'עליך להוסיף הטמעה של מפת גוגל של הסניף!'
                          return false  
                        }
                        break;
                    case 'map_url':
                        if(!value){
                          err_msg.value = 'עליך להוסיף קישור URL של המפה!' 
                          return false 
                        }
                        break;
                    case 'logo':
                        if(!value){
                          err_msg.value = 'עליך לבחור לוגו של סוג הסניף!' 
                          return false 
                        }
                        break;
                    case 'order':
                        if(!value){
                          err_msg.value = 'עליך להזין סדר הצגת הסניף!' 
                          return false 
                        }
                        break;
                    case 'opening_hours':
                        let flag = false
                        for(const day of value){
                            if(day.open && (!day.start_time || !day.end_time)){
                                err_msg.value = `עליך להשלים את הפרטים ביום ${day.day_name}!`
                                flag = true
                                return false
                            }
                        }
                        if(flag) return false
                        break;
                    case 'description':
                        if(!value){
                            err_msg.value = 'עליך לרשום תיאור שעות פתיחה!'
                            return false
                        }
                        break;
                }
            }

            return true
        }

        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                const data = await add_new_branche(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                `הסניף ${values.value.branche_name} הוסף בהצלחה`)
                .then(()=>{
                    emit('finish',data)
                })
            }
        }

        const handle_submit_edit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                await edit_exist_branche(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                `הסניף ${values.value.branche_name} נערך בהצלחה`)
                .then(()=>{
                    emit('edit_finish',values.value)
                })
            }
        }

        if(props.edit_data){
            values.value = props.edit_data
        }

        return{
            handle_submit,
            handle_submit_edit,
            logo_options,
            values,
            err_msg,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .create-new-branche{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        overflow-y: auto;
        background: rgba(0, 0, 0, 0.4);
        padding: 0 5px;
    }
    .create-new-branche-form{
        width: 100%;
        max-width: 500px;
        height: 80%;
        background: whitesmoke;
        border-radius: 5px;
        overflow: hidden;
        overflow-y: auto;
    }
    .create-new-branche-form::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        height: 0;
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    .create-new-branche-form-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .create-new-branche-form-header .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    .create-new-branche-form-content{
        width: 100%;
        height: fit-content;
    }
    .create-new-branche-form-content-field{
        width: 100%;
        height: fit-content;
        padding: 0 5px;
        margin-bottom: 5px;
    }
    .text-area{
        white-space: pre-wrap;
    }
    .day{
        width: 100%;
        height: 70px;
        background: #fff;
        margin-bottom: 5px;
        border-radius: 5px;
        box-shadow: 0 0 1px 1px rgb(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
    }
    .close-msg{
        color: red;
    }
</style>